import { devices } from '@src/styles/theme';
import Link from 'next/link';
import styled from 'styled-components';

const NotFoundPage = () => {
  return (
    <Container>
      <Wrap>
        <Title>404</Title>
        <br />
        <Content>원하시는 페이지를 찾을 수 없습니다.</Content>
        <Description>
          찾으려는 페이지의 주소가 잘못 입력되었거나,
          <br /> 주소의 변경 혹은 삭제로 인해 사용하실 수 없습니다.
          <br /> 입력하신 페이지의 주소가 정확한지 다시 한번 확인해주세요.
        </Description>
        <Home>
          <Link href={'/'}>피플 홈 가기</Link>
        </Home>
      </Wrap>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const Wrap = styled.section`
  text-align: center;
`;

const Title = styled.strong`
  font-size: 34px;
  font-weight: 700;
  @media ${devices.tablet} {
    font-size: 24px;
  }
`;

const Content = styled.strong`
  display: inline-block;
  margin: 24px 0px 16px 0px;
  font-weight: 600;
  font-size: 30px;
  @media ${devices.tablet} {
    font-size: 16px;
  }
`;

const Description = styled.p`
  color: ${({ theme }) => theme.commonColor.gray005};
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  letter-spacing: -0.6px;
  line-height: 32px;
`;

const Home = styled.button`
  background-color: ${({ theme }) => theme.commonColor.pink001};
  border-radius: 5px;
  width: 100px;
  height: 50px;
  color: ${({ theme }) => theme.commonColor.pink004};
  cursor: pointer;
  margin-top: 18px;
`;

export default NotFoundPage;
